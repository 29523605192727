import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  ReferenceArrayInput,
  NumberInput,
  AutocompleteArrayInput,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  FileField,
  DateInput,
} from 'react-admin';
import { RichTextInput, FormatButtons, RichTextInputToolbar } from 'ra-input-rich-text';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isTemplateAdmin, isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import {
  validateRequired,
  validateRequiredText,
  validateRequiredNumber,
  validateLink,
  validateRequiredLink,
} from 'utils/validation';
import { ACCESS_LEVEL_OPTIONS, PUBLISHING_STATUS_OPTIONS, ALLOWED_MODULE_FILE_TYPES } from './index';
import CustomMaskedInput from '../../CustomMaskedInput';
import { MAX_FILE_SIZE } from 'utils/consts';
import useRichTextStyles from 'hooks/useRichTextStyles';

const ModuleCreate = props => {
  const classes = useRichTextStyles();

  return (
    <Create title='Create an Module' {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput source='internalName' validate={validateRequiredText} />
        <TextInput source='catalogNumber' validate={validateRequiredText} />
        <TextInput source='title' validate={validateRequiredText} />
        <RichTextInput
          source='description'
          validate={validateRequiredText}
          toolbar={
            <RichTextInputToolbar>
              <FormatButtons />
            </RichTextInputToolbar>
          }
          formClassName={classes.richTextContainer}
        />
        <NumberInput source='learningCredits' step={0.25} validate={validateRequiredNumber} />
        <ReferenceArrayInput
          source='categories'
          label='Categories'
          reference='AdminCategory'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source='occupations'
          label='Occupations'
          reference='AdminOccupation'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source='presenters'
          label='Presenters'
          reference='AdminPresenter'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source='markets'
          label='Work Sectors'
          reference='AdminMarket'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <SelectInput
          source='accessLevel'
          label='Access Level'
          validate={validateRequired}
          choices={ACCESS_LEVEL_OPTIONS}
        />
        <SelectInput
          source='publishingStatus'
          label='Publishing Status'
          validate={validateRequired}
          choices={PUBLISHING_STATUS_OPTIONS}
        />

        {/*Is Required*/}
        <DateInput label='Featured Start' source='featuredStart' />
        <DateInput label='Featured End' source='featuredEnd' />
        <TextInput source='mp4Low' label='MP4 Low' validate={validateLink} />
        <TextInput source='mp4Medium' label='MP4 Medium' validate={validateLink} />
        <TextInput source='mp4High' label='MP4 High' validate={validateLink} />
        <TextInput source='mp4ExtraHigh' label='MP4 Extra High' validate={validateLink} />
        <TextInput source='webMLow' label='WebM Low' validate={validateLink} />
        <TextInput source='webMMedium' label='WebM Medium' validate={validateLink} />
        <TextInput source='webMHigh' label='WebM High' validate={validateLink} />
        <TextInput source='webMExtraHigh' label='WebM Extra High' validate={validateLink} />
        <TextInput source='videoCaptionLink' validate={validateLink} />
        <TextInput source='videoThumbnailLink' validate={validateLink} />
        <BooleanInput source='isBreezePresentation' label='Is Breeze Presentation' />
        <TextInput source='breezePresentationLink' validate={validateLink} />
        <ReferenceArrayInput
          source='privateContentAgencies'
          label='Private Content Agencies'
          reference='AdminAgency'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <BooleanInput source='isMasaContent' label='Is MASA Content' />
        <BooleanInput source='isBadgeContent' label='Is Badge Content' />
        <ArrayInput source='externalLinks'>
          <SimpleFormIterator>
            <TextInput source='linkText' label='Link Text' validate={validateRequiredText} />
            <TextInput source='url' label='Link URL' validate={validateRequiredLink} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput name='attachments' source='attachments'>
          <SimpleFormIterator>
            <TextInput source='linkText' label='Link Text' validate={validateRequiredText} />
            <FileInput
              source='fileUpload'
              label='Attachments'
              accept={ALLOWED_MODULE_FILE_TYPES.join(',')}
              multiple={false}
              maxSize={MAX_FILE_SIZE}
              labelSingle='Drop a file here, or click to select a file to attach.'
            >
              <FileField source='src' title='title' />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source='hasIsbeCompletionCertificate' label='Eligible for ISBE Completion Certificate' />
        <TextInput
          label='Playing Time'
          name='playingTimeInSeconds'
          source='playingTimeInSeconds'
          InputProps={{
            inputComponent: CustomMaskedInput,
            inputProps: {
              mask: [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/],
              placeholderChar: '\u2000',
            },
          }}
        />
        <BooleanInput source='isNewsletterAccessible' label='Is Newsletter Accessible' />
        <DateInput label='Newsletter Expiration' source='newsletterExpiration' />
      </SimpleForm>
    </Create>
  );
};

export default lockDown(ModuleCreate, [isUCPAdmin, isTemplateAdmin]);
