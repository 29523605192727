import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  dragContainer: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  dragIcon: {
    'font-size': '2rem',
  },
});

const DragHandle = SortableHandle(() => {
  const classes = useStyles();

  return (
    <div className={classes.dragContainer}>
      <DragHandleIcon className={classes.dragIcon} />
    </div>
  );
});

export default DragHandle;
