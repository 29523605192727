import React from 'react';
import { Resource } from 'react-admin';
import CategoryIcon from '@mui/icons-material/Category';

// resources
import { SecuredUserCreate, SecuredUserEdit, SecuredUserList, UserIcon } from 'components/admin/users';
import { SecuredStateCreate, SecuredStateEdit, SecuredStateList, StateIcon } from 'components/admin/states';
import { RegionIcon, SecuredRegionCreate, SecuredRegionEdit, SecuredRegionList } from 'components/admin/regions';
import {
  OccupationIcon,
  SecuredOccupationCreate,
  SecuredOccupationEdit,
  SecuredOccupationList,
} from 'components/admin/occupations';
import { MarketIcon, SecuredMarketCreate, SecuredMarketEdit, SecuredMarketList } from 'components/admin/markets';
import { AgencyIcon, SecuredAgencyCreate, SecuredAgencyEdit, SecuredAgencyList } from 'components/admin/agencies';
import {
  ContractIcon,
  SecuredContractCreate,
  SecuredContractEdit,
  SecuredContractList,
} from 'components/admin/contracts';
import { QuizIcon, SecuredQuizCreate, SecuredQuizEdit, SecuredQuizList } from 'components/admin/quiz';
import { ModuleIcon, SecuredModuleCreate, SecuredModuleEdit, SecuredModuleList } from 'components/admin/modules';
import {
  InfLearningTemplateIcon,
  SecuredInfLearningTemplateCreate,
  SecuredInfLearningTemplateEdit,
  SecuredInfLearningTemplateList,
} from 'components/admin/infLearningTemplates';
import { EventIcon, SecuredEventCreate, SecuredEventEdit, SecuredEventList } from 'components/admin/events';
import {
  PresenterIcon,
  SecuredPresenterCreate,
  SecuredPresenterEdit,
  SecuredPresenterList,
} from 'components/admin/presenters';
import {
  CustomPageIcon,
  SecuredCustomPageCreate,
  SecuredCustomPageEdit,
  SecuredCustomPageList,
} from 'components/admin/customPages';
import { SecuredCategoryCreate, SecuredCategoryEdit, SecuredCategoryList } from 'components/admin/categories';
import { SecuredTemplateCategoryCreate, SecuredTemplateCategoryEdit, SecuredTemplateCategoryList } from 'components/admin/templateCategories';
import { SecuredSiteMessageCreate, SecuredSiteMessageEdit, SecuredSiteMessagesList } from 'components/admin/siteMessages';
import { QuizAttemptIcon, SecuredQuizAttemptList } from 'components/admin/quizAttempts';
import { SecuredUserBadgeList, UserBadgeIcon } from 'components/admin/userBadges';
import BadgeResource from './badges';

/**
 * Resources in this list will be enabled for the application
 * The order of elements in the array represents the order they will appear in the sidebar navigation
 */
export default [
  <Resource
    name='AdminUser'
    list={SecuredUserList}
    create={SecuredUserCreate}
    edit={SecuredUserEdit}
    icon={UserIcon}
    options={{ label: 'Users' }}
    recordRepresentation={(record) => `${record.firstName} ${record.lastName}`}
  />,
  <Resource
    name='AdminModule'
    list={SecuredModuleList}
    create={SecuredModuleCreate}
    edit={SecuredModuleEdit}
    icon={ModuleIcon}
    options={{ label: 'Modules' }}
    recordRepresentation={(record) => record.title}
  />,
  <Resource
    name='AdminEvent'
    list={SecuredEventList}
    edit={SecuredEventEdit}
    create={SecuredEventCreate}
    icon={EventIcon}
    options={{ label: 'Events' }}
  />,
  <Resource
    name='AdminQuiz'
    list={SecuredQuizList}
    edit={SecuredQuizEdit}
    create={SecuredQuizCreate}
    icon={QuizIcon}
    options={{ label: 'Quizzes' }}
  />,
  <Resource
    name='AdminQuizAttempt'
    list={SecuredQuizAttemptList}
    icon={QuizAttemptIcon}
    options={{ label: 'Quiz Attempts' }}
  />,
  <Resource
    name='AdminUserBadge'
    list={SecuredUserBadgeList}
    icon={UserBadgeIcon}
    options={{ label: 'User Badges' }}
  />,
  <Resource
    name='AdminPresenter'
    list={SecuredPresenterList}
    edit={SecuredPresenterEdit}
    create={SecuredPresenterCreate}
    icon={PresenterIcon}
    options={{ label: 'Presenters' }}
  />,
  <Resource
    name='AdminAgency'
    list={SecuredAgencyList}
    edit={SecuredAgencyEdit}
    create={SecuredAgencyCreate}
    icon={AgencyIcon}
    options={{ label: 'Member Agencies' }}
  />,
  <Resource
    name='AdminContract'
    list={SecuredContractList}
    edit={SecuredContractEdit}
    create={SecuredContractCreate}
    icon={ContractIcon}
    options={{ label: 'Contracting Agencies' }}
  />,
  <Resource
    name='AdminState'
    list={SecuredStateList}
    edit={SecuredStateEdit}
    create={SecuredStateCreate}
    icon={StateIcon}
    options={{ label: 'States' }}
  />,
  <Resource
    name='AdminRegion'
    list={SecuredRegionList}
    edit={SecuredRegionEdit}
    create={SecuredRegionCreate}
    icon={RegionIcon}
    options={{ label: 'Regions' }}
  />,
  <Resource
    name='AdminOccupation'
    list={SecuredOccupationList}
    edit={SecuredOccupationEdit}
    create={SecuredOccupationCreate}
    icon={OccupationIcon}
    options={{ label: 'Occupations' }}
  />,
  <Resource
    name='AdminCategory'
    list={SecuredCategoryList}
    edit={SecuredCategoryEdit}
    create={SecuredCategoryCreate}
    icon={CategoryIcon}
    options={{ label: 'Categories' }}
  />,
  <Resource
    name='AdminTemplateCategory'
    list={SecuredTemplateCategoryList}
    edit={SecuredTemplateCategoryEdit}
    create={SecuredTemplateCategoryCreate}
    icon={CategoryIcon}
    options={{ label: 'ICLT Categories' }}
  />,
  <Resource
    name='AdminInfLearningTemplate'
    list={SecuredInfLearningTemplateList}
    create={SecuredInfLearningTemplateCreate}
    edit={SecuredInfLearningTemplateEdit}
    icon={InfLearningTemplateIcon}
    options={{ label: 'INF Learning Templates' }}
  />,
  BadgeResource,
  <Resource
    name='AdminMarket'
    list={SecuredMarketList}
    edit={SecuredMarketEdit}
    create={SecuredMarketCreate}
    icon={MarketIcon}
    options={{ label: 'Work Sector' }}
  />,
  <Resource
    name='AdminCustomPage'
    list={SecuredCustomPageList}
    edit={SecuredCustomPageEdit}
    create={SecuredCustomPageCreate}
    icon={CustomPageIcon}
    options={{ label: 'Pages' }}
  />,
  <Resource
    name='AdminSiteMessage'
    list={SecuredSiteMessagesList}
    edit={SecuredSiteMessageEdit}
    create={SecuredSiteMessageCreate}
    options={{ label: 'Site Messages' }}
  />,
  <Resource name='AdminLearningCampaign' options={{ label: 'Learning Campaigns' }} />,
];
