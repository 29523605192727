import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  EditButton,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  required,
  minLength,
  BooleanInput,
  BooleanField,
  SelectInput,
  useRecordContext,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';

const validateRequiredText = [required(), minLength(2)];

const SiteMessagesList = props => (
  <List title='SiteMessages' {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='message' />
      <BooleanField source='active' />
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredSiteMessagesList = lockDown(SiteMessagesList, [isUCPAdmin]);

const SiteMessageTitle = () => {
  const record = useRecordContext();
  return <span>Site Message {record ? `${record.id}` : ''}</span>;
};

const SiteMessageEdit = props => {
  return (
    <Edit title={<SiteMessageTitle />} mutationMode='pessimistic' {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput
          source='message'
          helperText={'Example: Site is undergoing maintenance, please check back later.'}
          validate={validateRequiredText}
        />
        <SelectInput
          source='type'
          choices={[
            { id: 'danger', name: 'Critical (red)' },
            { id: 'warning', name: 'Warning (yellow)' },
            { id: 'info', name: 'Info (green)' },
          ]}
        />
        <BooleanInput source='active' label='Active' defaultValue={false} />
      </SimpleForm>
    </Edit>
  );
};

export const SecuredSiteMessageEdit = lockDown(SiteMessageEdit, [isUCPAdmin]);

const SiteMessageCreate = props => {
  return (
    <Create title='Create a State' {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput
          source='message'
          helperText={'Example: Site is undergoing maintenance, please check back later.'}
          validate={validateRequiredText}
        />
        <SelectInput
          source='type'
          choices={[
            { id: 'danger', name: 'Critical (red)' },
            { id: 'warning', name: 'Warning (yellow)' },
            { id: 'info', name: 'Info (green)' },
          ]}
        />
        <BooleanInput source='active' label='Active' defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};

export const SecuredSiteMessageCreate = lockDown(SiteMessageCreate, [isUCPAdmin]);
