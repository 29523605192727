import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';

const AddButton = ({ handleClick }) => {

  return (
      <Button
        onClick={handleClick}
        aria-label='Add button'
      >
          <AddCircleOutlineIcon sx={{marginRight: '0.4rem'}}/> Add
      </Button>
  );
};

export default AddButton;
