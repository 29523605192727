import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  useRecordContext,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import { isTemplateAdmin, isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { validateRequiredText } from 'utils/validation';
import SearchFilter from 'components/SearchFilter';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';

const TemplateCategoryList = props => (
  <List title='ICLT Categories' {...props} filters={<SearchFilter />} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' />
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredTemplateCategoryList = lockDown(TemplateCategoryList, [isUCPAdmin, isTemplateAdmin]);

const TemplateCategoryTitle = () => {
  const record = useRecordContext();
  return <span>ICLT Category {record ? `${record.name}` : ''}</span>;
};

const TemplateCategoryEdit = props => (
  <Edit title={<TemplateCategoryTitle />} mutationMode='pessimistic' {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Edit>
);

export const SecuredTemplateCategoryEdit = lockDown(TemplateCategoryEdit, [isUCPAdmin, isTemplateAdmin]);

const TemplateCategoryCreate = props => (
  <Create redirect='list' title='Create an ICLT Category' {...props}>
    <SimpleForm>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Create>
);

export const SecuredTemplateCategoryCreate = lockDown(TemplateCategoryCreate, [isUCPAdmin, isTemplateAdmin]);
