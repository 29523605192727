import React from 'react';
import { Button, useListContext, useNotify } from 'react-admin';
import { getToken } from 'utils/auth';
import reduce from 'lodash/reduce';
import axios from 'axios';

function stringifyObjects(params) {
  // convert date filter for request
  if (params.createdAt?.startDate) {
    params['createdAt.startDate'] = params.createdAt.startDate;
  }
  if (params.createdAt?.endDate) {
    params['createdAt.endDate'] = params.createdAt.endDate;
  }
  delete params.createdAt;

  if (params.userMeta?.state) {
    params['userMeta.state'] = params.userMeta.state;
  }
  delete params.userMeta;

  if (params.moduleMeta?.markets) {
    params['moduleMeta.markets'] = params.moduleMeta.markets;
  }
  delete params.moduleMeta;

  return params;
}

function CustomExportButton({ jobName }) {
  const { filterValues, sort, isLoading, total } = useListContext();
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();

  const params = stringifyObjects({
    ...filterValues,
    sortField: sort?.field || 'moduleId',
    sortOrder: sort?.order || 'ASC',
  });

  const paramString = React.useMemo(
    () =>
      reduce(
        params,
        (acc, value, index) => acc + `&${index}=${value}`,
        `?jobName=${jobName}&bearerToken=${getToken()}`
      ),
    [filterValues, sort]
  );

  const handleClick = async () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/export${paramString}`, { responseType: 'json' })
      .then(({ status }) => {
        if (status === 200) {
          notify('Your report is processing and will be emailed to you once completed.');
        }
      })
      .catch(err => notify('Sorry something went wrong. Please try again.', 'warning'))
      .finally(() => setLoading(false));
  };

  return <Button label='Export' onClick={handleClick} disabled={total === 0 || loading || isLoading} />;
}

export default CustomExportButton;
