import React from 'react';
import { FormatButtons, RichTextInputToolbar } from 'ra-input-rich-text';

const RichTextToolbar = (
  <RichTextInputToolbar>
    <FormatButtons />
  </RichTextInputToolbar>
)

export default RichTextToolbar;
