import React from 'react';
import {
  Edit,
  TextInput,
  SelectInput,
  BooleanInput,
  ReferenceArrayInput,
  NumberInput,
  AutocompleteArrayInput,
  ArrayInput,
  FileInput,
  FileField,
  FormTab,
  TabbedForm,
  TextField,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceManyField,
  Datagrid,
  EditButton,
  DateInput,
  Labeled,
} from 'react-admin';
import { RichTextInput, RichTextInputToolbar, FormatButtons } from 'ra-input-rich-text';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isTemplateAdmin, isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { ALLOWED_MODULE_FILE_TYPES, ModuleTitle } from './index';
import {
  validateRequiredText,
  validateRequiredNumber,
  validateRequired,
  validateLink,
  validateRequiredLink,
} from 'utils/validation';
import { ACCESS_LEVEL_OPTIONS, PUBLISHING_STATUS_OPTIONS } from './index';
import CustomMaskedInput from '../../CustomMaskedInput';
import useRichTextStyles from 'hooks/useRichTextStyles';

const ModuleEdit = props => {
  const classes = useRichTextStyles();

  return (
    <>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <Edit title={<ModuleTitle />} mutationMode='pessimistic' {...props}>
        <TabbedForm>
          <FormTab label='Module Options'>
            <TextInput name='internalName' source='internalName' validate={validateRequiredText} />
            <TextInput name='catalogNumber' source='catalogNumber' validate={validateRequiredText} />
            <TextInput name='title' source='title' validate={validateRequiredText} />
            <RichTextInput
              name='description'
              source='description'
              validate={validateRequiredText}
              toolbar={
                <RichTextInputToolbar>
                  <FormatButtons />
                </RichTextInputToolbar>
              }
              formClassName={classes.richTextContainer}
            />
            <NumberInput source='learningCredits' step={0.25} validate={validateRequiredNumber} />
            <ReferenceArrayInput
              source='categories'
              label='Categories'
              reference='AdminCategory'
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteArrayInput optionText='name' />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              source='occupations'
              label='Occupations'
              reference='AdminOccupation'
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteArrayInput optionText='name' />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              source='presenters'
              label='Presenters'
              reference='AdminPresenter'
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteArrayInput optionText='name' />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              source='markets'
              label='Work Sectors'
              reference='AdminMarket'
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteArrayInput optionText='name' />
            </ReferenceArrayInput>

            <SelectInput
              source='accessLevel'
              label='Access Level'
              validate={validateRequired}
              choices={ACCESS_LEVEL_OPTIONS}
            />
            <SelectInput
              source='publishingStatus'
              label='Publishing Status'
              validate={validateRequired}
              choices={PUBLISHING_STATUS_OPTIONS}
            />

            {/*Is Required*/}
            <DateInput label='Featured Start' source='featuredStart' />
            <DateInput label='Featured End' source='featuredEnd' />
            <TextInput source='mp4Low' label='MP4 Low' validate={validateLink} />
            <TextInput source='mp4Medium' label='MP4 Medium' validate={validateLink} />
            <TextInput source='mp4High' label='MP4 High' validate={validateLink} />
            <TextInput source='mp4ExtraHigh' label='MP4 High Extra' validate={validateLink} />
            <TextInput source='webMLow' label='WebM Low' validate={validateLink} />
            <TextInput source='webMMedium' label='WebM Medium' validate={validateLink} />
            <TextInput source='webMHigh' label='WebM High' validate={validateLink} />
            <TextInput source='webMExtraHigh' label='WebM Extra High' validate={validateLink} />
            <TextInput source='videoCaptionLink' validate={validateLink} />
            <TextInput source='videoThumbnailLink' validate={validateLink} />
            <BooleanInput source='isBreezePresentation' label='Is Breeze Presentation' />
            <TextInput source='breezePresentationLink' validate={validateLink} />
            <ReferenceArrayInput
              source='privateContentAgencies'
              label='Private Content Agencies'
              reference='AdminAgency'
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteArrayInput optionText='name' />
            </ReferenceArrayInput>
            <BooleanInput source='isMasaContent' label='Is MASA Content' />
            <BooleanInput source='isBadgeContent' label='Is Badge Content' />
            <ArrayInput source='externalLinks'>
              <SimpleFormIterator>
                <TextInput source='linkText' label='Link Text' validate={validateRequiredText} />
                <TextInput source='url' label='Link URL' validate={validateRequiredLink} />
              </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source='attachments'>
              <SimpleFormIterator>
                <TextInput source='linkText' label='Link Text' validate={validateRequiredText} />

                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    getSource();
                    return (
                      <Labeled label='File Name'>
                        <TextField source='document.fileName' record={scopedFormData} />
                      </Labeled>
                    );
                  }}
                </FormDataConsumer>
                <FileInput
                  source='fileUpload'
                  label='Attachments'
                  accept={ALLOWED_MODULE_FILE_TYPES.join(',')}
                  multiple={false}
                  labelSingle='Drop a file here, or click to select a file to attach.'
                >
                  <FileField source='src' title='title' />
                </FileInput>
              </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source='hasIsbeCompletionCertificate' label='Eligible for ISBE Completion Certificate' />
            <TextInput
              label='Playing Time'
              name='playingTimeInSeconds'
              source='playingTimeInSeconds'
              InputProps={{
                inputComponent: CustomMaskedInput,
                inputProps: {
                  mask: [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/],
                  placeholderChar: '\u2000',
                },
              }}
            />
            <BooleanInput source='isNewsletterAccessible' label='Is Newsletter Accessible' />
            <DateInput label='Newsletter Expiration' source='newsletterExpiration' />
          </FormTab>
          <FormTab label='INF Learning Templates'>
            <FormDataConsumer>
              {props => (
                <>
                  <ReferenceManyField
                    {...props}
                    label='INF Learning Campaigns'
                    reference='AdminInfLearningTemplate'
                    target='adminModule'
                  >
                    <Datagrid>
                      <TextField source='name' />
                      <TextField source='status' />
                      <EditButton />
                    </Datagrid>
                  </ReferenceManyField>
                </>
              )}
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  );
};

export default lockDown(ModuleEdit, [isUCPAdmin, isTemplateAdmin]);
