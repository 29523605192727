import React from 'react';
import { AutocompleteInput, ReferenceInput, TextInput } from 'react-admin';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

export const BadgeTemplateFilter = props => (
  <SavelessFilter {...props}>
    <TextInput name='q' label='Search' source='q' alwaysOn />
    <ReferenceInput name='markets' label='Work Sector' source='markets' reference='AdminMarket' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput name='markets' optionText='name' />
    </ReferenceInput>
  </SavelessFilter>
);

export default BadgeTemplateFilter;
