import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  FileField,
} from 'react-admin';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { validateRequired, validateRequiredText } from 'utils/validation';
import { CustomEditorOptions, CustomRichTextInputToolbar } from './index';
import { ALLOWED_MODULE_FILE_TYPES } from '../modules';
import { MAX_FILE_SIZE } from 'utils/consts';
import useRichTextStyles from 'hooks/useRichTextStyles';
import CustomRichTextInput from 'components/CustomRichTextInput/CustomRichTextInput';

const CustomPageCreate = props => {
  const classes = useRichTextStyles();

  return (
    <Create title='Create a Page' mutationMode='pessimistic' {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <ReferenceInput
          label='Parent Page'
          name='parentContainer'
          source='parent'
          reference='AdminCustomPage'
          sort={{ field: 'title', order: 'ASC' }}
        >
          <AutocompleteInput name='parent' optionText='title' />
        </ReferenceInput>
        <BooleanInput name='published' source='published' />
        <TextInput name='slug' source='slug' validate={validateRequired} />
        <TextInput name='title' source='title' validate={validateRequired} />

        <ReferenceInput
          label='Copy Page'
          name='copyFromPageContainer'
          source='copyFromPage'
          reference='AdminCustomPage'
          sort={{ field: 'title', order: 'ASC' }}
        >
          <AutocompleteInput name='copyFromPage' optionText='title' />
        </ReferenceInput>

        <CustomRichTextInput
          name='mainBody'
          source='mainBody'
          label='Main Body'
          editorOptions={CustomEditorOptions}
          toolbar={<CustomRichTextInputToolbar />}
          formClassName={classes.richTextContainer}
        />
        <CustomRichTextInput
          name='rightRail'
          source='rightRail'
          label='Right Rail'
          editorOptions={CustomEditorOptions}
          toolbar={<CustomRichTextInputToolbar />}
          formClassName={classes.richTextContainer}
        />
        <BooleanInput name='isInfinitextAccessPage' label='Is InfiniTEXT Access Page' source='isInfinitextAccessPage' />
        <ArrayInput name='attachments' source='attachments'>
          <SimpleFormIterator>
            <TextInput source='linkText' label='Link Text' validate={validateRequiredText} />
            <FileInput
              source='fileUpload'
              label='Attachments'
              accept={ALLOWED_MODULE_FILE_TYPES.join(',')}
              multiple={false}
              maxSize={MAX_FILE_SIZE}
              labelSingle='Drop a file here, or click to select a file to attach.'
            >
              <FileField source='src' title='title' />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default lockDown(CustomPageCreate, [isUCPAdmin]);
