import React from 'react';
import ViewCustomPageIcon from '@mui/icons-material/Pages';
import SecuredCustomPageCreate from './CustomPageCreate';
import SecuredCustomPageList from './CustomPageList';
import SecuredCustomPageEdit from './CustomPageEdit';
import { AutocompleteInput, ReferenceInput, TextInput, BooleanInput, useRecordContext } from 'react-admin';
import {
  AlignmentButtons,
  ClearButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  DefaultEditorOptions,
  RichTextInputToolbar,
  useTiptapEditor,
} from 'ra-input-rich-text';
import Youtube from '@tiptap/extension-youtube';
import ImageExtension from '@tiptap/extension-image';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Indent } from 'components/CustomRichTextInput/indent';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';
import { convertFileToBase64 } from 'utils/dataProvider';
import CustomImageButton from './components/CustomImageButton';

export const CustomRichTextInputToolbar = ({ size = 'medium', ...props }) => {
  const editor = useTiptapEditor();
  const addYoutubeVideo = () => {
    const url = prompt('Enter YouTube URL');

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: 640,
        height: 480,
      });
    }
  };
  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ToggleButtonGroup>
        <ToggleButton
          value='outdent'
          title='Outdent'
          aria-label='Outdent'
          onClick={() => editor.commands.outdent()}
        >
          <FormatIndentDecreaseIcon/>
        </ToggleButton>
        <ToggleButton
          value='indent'
          title='Indent'
          aria-label='Indent'
          onClick={() => editor.commands.indent()}
        >
          <FormatIndentIncreaseIcon/>
        </ToggleButton>
      </ToggleButtonGroup>
      <ListButtons size={size} />
      <CustomImageButton size={size} label={'Upload an Image from your computer'}/>
      <ToggleButton
        aria-label='Add YouTube Video'
        title='Add YouTube Video'
        value='Add YouTube Video'
        onClick={addYoutubeVideo}
        selected={editor && editor.isActive('youtubeVideo')}
      >
        <YouTubeIcon fontSize='inherit' />
      </ToggleButton>
      <LinkButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
    </RichTextInputToolbar>
  );
};


export const CustomEditorOptions = {
  ...DefaultEditorOptions,
  editorProps: {
    //@see https://www.codemzy.com/blog/tiptap-drag-drop-image
    handleDrop: (view, event, slice, moved) => {
      if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) { // if dropping external files
        let file = event.dataTransfer.files[0]; // the dropped file
        let filesize = ((file.size/1024)/1024).toFixed(4); // get the filesize in MB
        if ((file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg') && filesize < 10) { // check valid image type under 10MB
          const dataPromise = convertFileToBase64(file);
          dataPromise.then((base64Image) => {
            const { schema } = view.state;
            const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
            const node = schema.nodes.image.create({ src:  base64Image}); // creates the image element
            const transaction = view.state.tr.insert(coordinates.pos, node); // places it in the correct position
            return view.dispatch(transaction);
          }).catch((error) => window.alert(`Image failed to process successfully: ${error}`));
        } else {
          window.alert('Images need to be in jpg, png, or svg format and less than 10mb in size.');
        }
        return true; // handled
      }
      return false; // not handled use default behaviour
    },
  },
  extensions: [
    ...DefaultEditorOptions.extensions,
    Youtube,
    Indent.configure({
      types: ['listItem', 'paragraph'],
      minLevel: 0,
      maxLevel: 8,
    }),
    ImageExtension.configure({
      inline: true,
      allowBase64: true,
    }),
  ],
};

export const CustomPageIcon = ViewCustomPageIcon;

export const CustomPageTitle = () => {
  const record = useRecordContext();
  return <span>page {record ? `"${record.title}"` : ''}</span>;
};

export const CustomPageFilter = props => (
  <SavelessFilter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <ReferenceInput
      label='Parent Page'
      source='parent'
      reference='AdminCustomPage'
      sort={{ field: 'title', order: 'ASC' }}
    >
      <AutocompleteInput optionText='title' />
    </ReferenceInput>
    <BooleanInput source='published' />
  </SavelessFilter>
);

export { SecuredCustomPageList, SecuredCustomPageCreate, SecuredCustomPageEdit };
