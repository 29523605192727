import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import { isUCPAdmin, isUserAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import get from 'lodash/get';
import BaseUserFieldGroup from './components/BaseUserFieldGroup';

const UserCreate = ({ permissions, ...props }) => (
  <Create title='Create a User' {...props}>
    <SimpleForm>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <BaseUserFieldGroup />
    </SimpleForm>
  </Create>
);

export default lockDown(UserCreate, [isUCPAdmin, isUserAdmin]);
