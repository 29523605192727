import React, {cloneElement} from 'react';
import {
  List,
  Datagrid,
  DateInput,
  TextField,
  ImageField,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import VerifiedIcon from '@mui/icons-material/Verified';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@mui/styles';
import CustomExportButton from '../../CustomExportButton';
import { JOB_FUNCTION_NAMES } from '../../../utils/consts';
import { formatSecondsToHMS } from '../../../utils/dataProvider';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

const useStyles = makeStyles({
  userBadgesList: {
    '& > .MuiToolbar-root': {
      zIndex: 10,
    },
  },
});

const FullNameField = () => {
  const record = useRecordContext();
  return (<span>{record.userMeta.firstName} {record.userMeta.lastName}</span>)
}

const SeatTimeField = () => {
  const record = useRecordContext();
  return (<span>{formatSecondsToHMS(record.badgeMeta.seatTime)}</span>)
}

const UserBadgesFilter = (props) => (
  <SavelessFilter {...props}>
    <TextInput label='Search' name='q' source='q' alwaysOn />
    <TextInput label='Badge Name' name='badgeMeta.name' source='badgeMeta.name' />
    <TextInput label='User Email' name='userMeta.email' source='userMeta.email' />
    <ReferenceInput
      label='Member Agency'
      name='userMeta.memberAgency'
      reference='AdminAgency'
      source='userMeta.memberAgency'
    >
      <AutocompleteInput label='Member Agency' optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      label='State'
      name='userMeta.state'
      source='userMeta.state'
      reference='AdminState'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput label='State' optionText='name' />
    </ReferenceInput>
    <DateInput
      label='Campaign Start Date'
      name='learningCampaignMeta.startDate'
      source='learningCampaignMeta.startDate'
    />
    <DateInput
      label='Campaign End Date'
      name='learningCampaignMeta.endDate'
      source='learningCampaignMeta.endDate'
    />
    <ReferenceInput
      label='Work Sector'
      name='contractMeta.markets'
      reference='AdminMarket'
      source='contractMeta.markets'
    >
      <AutocompleteInput label='Work Sector' optionText='name' />
    </ReferenceInput>
  </SavelessFilter>
);

const UserBadgesListActions = ({ filters, ...props }) => (
  <TopToolbar>
    {filters && cloneElement(filters, { ...props, context: 'button' })}
    <CustomExportButton jobName={JOB_FUNCTION_NAMES.USER_BADGE_REPORT} />
  </TopToolbar>
);

export const UserBadgeIcon = VerifiedIcon;

const UserBadgesList = props => {
  const classes = useStyles();

  return (
    <List
      className={classes.userBadgesList}
      title='User Badge'
      {...props}
      sort={{ field: 'badgeId', order: 'ASC' }}
      filters={<UserBadgesFilter />}
      actions={<UserBadgesListActions />}
    >
      <Datagrid>
        <Helmet>
          <title>{props.options.label}</title>
        </Helmet>
        <ImageField label='Badge' source='badgeMeta.image.signedUrl' />
        <FullNameField label='User Name' source='userMeta.firstName' />
        <TextField label='User Email' source='userMeta.email' />
        <TextField label='Awarded Date' source='completedAt' />
        <SeatTimeField label='Seat Time' source='badgeMeta.seatTime' />
      </Datagrid>
    </List>
  );
};

export const SecuredUserBadgeList = lockDown(UserBadgesList, [isUCPAdmin]);
