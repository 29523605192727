import React from 'react';
import {
  Create,
  ImageInput,
  ImageField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TabbedForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  ArrayInput,
  FormTab,
  BooleanInput,
  required,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Helmet } from 'react-helmet';
import { lockDown } from 'utils/auth';
import { isTemplateAdmin, isUCPAdmin } from 'utils/roles';
import { validateRequiredText } from 'utils/validation';
import { MAX_FILE_SIZE, MODULE_STATUS_PUBLISHED, MODULE_STATUS_PUBLISHED_HIDDEN } from 'utils/consts';
import { ALLOWED_BADGE_FILE_TYPES } from './index';
import useRichTextStyles from 'hooks/useRichTextStyles';
import { PUBLISHING_STATUS_OPTIONS } from 'components/admin/infLearningTemplates';
import SortableModulesIterator from 'components/admin/infLearningTemplates/components/SortableModulesIterator';
import RichTextToolbar from 'components/RichTextToolbar';

const BadgeCreate = props => {
  const classes = useRichTextStyles();

  const moduleFilter = {
    publishingStatus: [MODULE_STATUS_PUBLISHED, MODULE_STATUS_PUBLISHED_HIDDEN]
  };

  return (
    <Create redirect='list' title='Create a Badge' {...props}>
      <TabbedForm>
        <FormTab label='Badge Info'>
          <Helmet>
            <title>{props?.options?.label}</title>
          </Helmet>
          <TextInput name='badge.name' source='badge.name' label='Badge Name' validate={validateRequiredText} />
          <TextInput name='badge.number' source='badge.number' label='Internal Badge Number' validate={[required()]} />
          <TextInput name='campaignType' source='campaignType' defaultValue='assignment' label='Campaign Type' style={{ display: 'none' }}/>
          <SelectInput
            name='badge.level'
            source='badge.level'
            choices={[{ id: 'Level 1: Foundation', name: 'Level 1: Foundation' }]}
            validate={[required()]}
          />
          <ReferenceInput
            label='Module'
            name='badge.introModule'
            source='badge.introModule'
            reference='AdminModule'
            filter={{ isBadgeContent: true }}
            sort={{ field: 'internalName', order: 'ASC' }}
          >
            <AutocompleteInput
              optionText={module => `${module.internalName} (${module.publishingStatus})`}
              validate={[required()]}
            />
          </ReferenceInput>
          <ReferenceInput
            label='Module'
            name='badge.outroModule'
            source='badge.outroModule'
            reference='AdminModule'
            filter={{ isBadgeContent: true }}
            sort={{ field: 'internalName', order: 'ASC' }}
          >
            <AutocompleteInput
              optionText={module => `${module.internalName} (${module.publishingStatus})`}
              validate={[required()]}
            />
          </ReferenceInput>
          <br />

          <RichTextInput
            name='badge.description'
            source='badge.description'
            label='Description'
            validate={[required()]}
            toolbar={RichTextToolbar}
            formClassName={classes.richTextContainer}
          />
          <RichTextInput
            name='badge.learningOutcome'
            source='badge.learningOutcome'
            label='Learning Outcomes'
            validate={[required()]}
            toolbar={RichTextToolbar}
            formClassName={classes.richTextContainer}
          />
          <br />

          <ImageInput
            name='badge.image'
            source='badge.image'
            label='Image'
            accept={ALLOWED_BADGE_FILE_TYPES.join(',')}
            multiple={false}
            maxSize={MAX_FILE_SIZE}
            validate={[required()]}
            labelSingle='Drop an image here, or click to select an image to attach.'
            helperText='Images that are 200 x 280 will look best for badges.'
          >
            <ImageField source='src' title='title' />
          </ImageInput>
        </FormTab>

        <FormTab label='Campaign Template'>
          <TextInput name='name' label='Campaign Name' source='name' validate={validateRequiredText} />
          <SelectInput
            name='status'
            source='status'
            label='Publishing Status'
            validate={[required()]}
            choices={PUBLISHING_STATUS_OPTIONS}
          />

          <RichTextInput
            name='description'
            source='description'
            label='Campaign Description'
            toolbar={RichTextToolbar}
            formClassName={classes.richTextContainer}
            validate={[required()]}
          />
          <RichTextInput
            name='instructions'
            source='instructions'
            label='Campaign Instructions'
            toolbar={RichTextToolbar}
            formClassName={classes.richTextContainer}
            validate={[required()]}
          />
          <br />

          <ReferenceArrayInput
            name='markets'
            source='markets'
            label='Work Sectors'
            reference='AdminMarket'
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteArrayInput name='markets' optionText='name' validate={[required()]} />
          </ReferenceArrayInput>

          <BooleanInput label='Include Discussion Forum' name='includeDiscussionForum' source='includedDiscussionForum' />

          {/* Activity Sequencing */}
          <BooleanInput label='Enforce Activity Sequence' name='enforceActivitySequence' source='enforceActivitySequence' />
          <BooleanInput label='Allow Sequence Override' name='allowSequenceOverride' source='allowSequenceOverride' />
          <br />

          <ArrayInput name='modules' label='Learning Path' source='modules'>
            <SortableModulesIterator moduleFilter={moduleFilter} />
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default lockDown(BadgeCreate, [isUCPAdmin, isTemplateAdmin]);
