import { makeStyles } from '@mui/styles';

const useRichTextStyles = () => {
  return makeStyles({
    richTextContainer: {
      '& .ql-container, & .ql-editor': {
        minHeight: '200px',
      },
    },
  });
};

export default useRichTextStyles;
