import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  EditButton,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  required,
  minLength, useRecordContext,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { RichTextInput } from 'ra-input-rich-text';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import useRichTextStyles from 'hooks/useRichTextStyles';

const validateRequiredText = [required(), minLength(2)];

export const StateIcon = LocationCityIcon;

const StateList = props => (
  <List title='States' {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' label={'State Name'} />
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredStateList = lockDown(StateList, [isUCPAdmin]);

const StateTitle = () => {
  const record = useRecordContext();
  return <span>State {record ? `${record.name}` : ''}</span>;
};

const StateEdit = props => {
  const classes = useRichTextStyles();

  return (
    <Edit title={<StateTitle />} mutationMode='pessimistic' {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput
          source='name'
          label={'State Name'}
          helperText={'Example: Illinois'}
          validate={validateRequiredText}
        />
        <RichTextInput
          source='continuingEducationUnits'
          label='Continuing Education Units'
          formClassName={classes.richTextContainer}
        />
      </SimpleForm>
    </Edit>
  );
};

export const SecuredStateEdit = lockDown(StateEdit, [isUCPAdmin]);

const StateCreate = props => {
  const classes = useRichTextStyles();

  return (
    <Create title='Create a State' {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput
          source='name'
          label={'State Name'}
          helperText={'Example: Illinois'}
          validate={validateRequiredText}
        />
        <RichTextInput
          source='continuingEducationUnits'
          label='Continuing Education Units'
          formClassName={classes.richTextContainer}
        />
      </SimpleForm>
    </Create>
  );
};

export const SecuredStateCreate = lockDown(StateCreate, [isUCPAdmin]);
