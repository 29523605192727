import React from 'react';
import moment from 'moment';
import { useRecordContext } from 'react-admin';

const UtcDateField = ({ source }) => {
  const record = useRecordContext() ?? {};
  return <span>{moment.utc(record[source]).format('M/DD/YYYY')}</span>;
};

export default UtcDateField;
