export const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile ?? file);
  });

export const getUploadFileArray = async fileUploadArray => {
  const newFiles = fileUploadArray.filter(p => p?.fileUpload?.rawFile instanceof File);
  const formerFiles = fileUploadArray.filter(p => p?.document);

  const base64Files = await Promise.all(
    newFiles.map(async newFile => {
      return {
        linkText: newFile.linkText,
        fileUpload: {
          src: await convertFileToBase64(newFile.fileUpload),
          title: newFile.fileUpload.title,
        },
      };
    })
  );
  return [
    ...base64Files,
    ...formerFiles.map(formerFile => {
      return {
        documentId: formerFile.document.id,
        linkText: formerFile.linkText,
      };
    }),
  ];
};

export const formatLogoUploadFile = async logoUpload => {
  if (logoUpload?.rawFile instanceof File) {
    return {
      src: await convertFileToBase64(logoUpload),
      title: logoUpload.title,
    };
  }
};

/**
 * Takes seconds, and returns Hours:Minutes:Seconds
 * @param seconds
 * @return {`${string}:${string}:${string}`}
 */
export const formatSecondsToHMS = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
