import * as React from 'react';
import { Children } from 'react';
import PropTypes from 'prop-types';
import { FilterButton, FilterForm, FilterContext, FilterClasses } from 'react-admin';

/**
 * Filter button/form combo
 *
 * @example
 *
 * const PostFilter = (props) => (
 *     <Filter {...props}>
 *         <TextInput label="Search" source="q" alwaysOn />
 *         <TextInput label="Title" source="title" defaultValue="Hello, World!" />
 *     </Filter>
 * );
 *
 * export const PostList = () => (
 *     <List filters={<PostFilter />}>
 *         ...
 *     </List>
 * );
 *
 */
export const SavelessFilter = (props) => {
  const { children } = props;
  const renderButton = () => {
    return <FilterButton className={FilterClasses.button} disableSaveQuery />;
  };

  const renderForm = () => {
    return <FilterForm className={FilterClasses.form} />;
  };

  return (
    <FilterContext.Provider value={Children.toArray(children)}>
      {props.context === 'button' ? renderButton() : renderForm()}
    </FilterContext.Provider>
  );
};

SavelessFilter.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  context: PropTypes.oneOf(['form', 'button']),
};
