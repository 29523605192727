import { getUserData, isAuthenticated } from './auth';

export const ROLE_SUPER_ADMIN = 'superadmin';
export const ROLE_UCP_ADMIN = 'ucp_admin';
export const ROLE_TEMPLATE_ADMIN = 'template_admin';
export const ROLE_CONTENT_ADMIN = 'content_admin';
export const ROLE_QUIZ_ADMIN = 'quiz_admin';
export const ROLE_TRAINING_ADMIN = 'training_admin';
export const ROLE_USER_ADMIN = 'user_admin';
export const ROLE_USER = 'user';

export const ROLE_ARRAY = [
  ROLE_SUPER_ADMIN,
  ROLE_UCP_ADMIN,
  ROLE_TEMPLATE_ADMIN,
  ROLE_CONTENT_ADMIN,
  ROLE_QUIZ_ADMIN,
  ROLE_USER_ADMIN,
  ROLE_TRAINING_ADMIN,
  ROLE_USER,
];

export const ROLE_LABELS = {
  [ROLE_SUPER_ADMIN]: 'Super Admin',
  [ROLE_UCP_ADMIN]: 'UCP Admin',
  [ROLE_TEMPLATE_ADMIN]: 'Template Admin',
  [ROLE_CONTENT_ADMIN]: 'Content Admin',
  [ROLE_QUIZ_ADMIN]: 'Quiz Admin',
  [ROLE_TRAINING_ADMIN]: 'Training Admin',
  [ROLE_USER_ADMIN]: 'User Admin',
  [ROLE_USER]: 'User',
};

export const ROLES_ALLOWED_ADMIN = [
  ROLE_SUPER_ADMIN,
  ROLE_UCP_ADMIN,
  ROLE_CONTENT_ADMIN,
  ROLE_TEMPLATE_ADMIN,
  ROLE_QUIZ_ADMIN,
  ROLE_USER_ADMIN,
];

export const isAllowedAdmin = user =>
  !!ROLES_ALLOWED_ADMIN.find(role => user && user.roles && user.roles.includes(role));

export const isSuperAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_SUPER_ADMIN) > -1;
  }
  return false;
};

export const isUCPAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_UCP_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isTemplateAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_TEMPLATE_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isContentAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_CONTENT_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isQuizAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_QUIZ_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isTrainingAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_TRAINING_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isUserAdmin = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_USER_ADMIN) > -1 || isSuperAdmin(user);
  }
  return false;
};

export const isUser = user => {
  if (isAuthenticated()) {
    const { roles } = user || getUserData();
    return roles.indexOf(ROLE_USER) > -1 || isSuperAdmin(user);
  }
  return false;
};
