import React, { cloneElement } from 'react';
import { Helmet } from 'react-helmet';
import SecuredUserCreate from './UserCreate';
import SecuredUserEdit from './UserEdit';
import {
  BooleanInput,
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  EmailField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  TopToolbar,
  CreateButton,
  Button,
  useRecordContext,
} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import { ROLE_LABELS, ROLE_USER, ROLE_ARRAY, isUCPAdmin, isUserAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import get from 'lodash/get';
import { JOB_FUNCTION_NAMES } from '../../../utils/consts';
import CustomExportButton from '../../CustomExportButton';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

export const roleOptions = ROLE_ARRAY.map(role => ({
  id: role,
  name: ROLE_LABELS[role],
}));

export const rolesInitialValue = [ROLE_USER];

export const UserIcon = PersonIcon;

export const UserTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `"${record.firstName} ${record.lastName}"` : ''}</span>;
};

const UserFilter = (props) => (
  <SavelessFilter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <TextInput label='Email' source='email' />
    <TextInput label='First Name' source='firstName' />
    <TextInput label='Last Name' source='lastName' />
    <ReferenceInput label='Agency' source='memberAgency' reference='AdminAgency' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
    <BooleanInput label='Is M.A.S.A. Member' source='isMasaMember' />
  </SavelessFilter>
);

const UserListActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
}) => {
  return (
    <TopToolbar>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton />

      <CustomExportButton jobName={JOB_FUNCTION_NAMES.USER_EXPORT_REPORT}/>
      <Button
        label='Manage Emails'
        onClick={() => {
          window.location = '#/emailUpdate';
        }}
      />
    </TopToolbar>
  );
};

const UserList = props => {
  return (
    <List
      title='Users'
      {...props}
      filters={<UserFilter />}
      sort={{ field: 'firstName', order: 'ASC' }}
      actions={<UserListActions />}
    >
      <Datagrid>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextField source='firstName' />
        <TextField source='lastName' />
        <EmailField source='email' />
        <ReferenceField label='Agency' source='memberAgency' reference='AdminAgency' sortable={false}>
          <TextField source='name' />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const SecuredUserList = lockDown(UserList, [isUCPAdmin, isUserAdmin]);

export { SecuredUserCreate, SecuredUserEdit };
