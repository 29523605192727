import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const CustomMaskedInput = React.forwardRef(function CustomMaskedInput(props, ref) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        if (inputRef) {
          return inputRef(ref ? ref.inputElement : null);
        }
        return ref ? ref.inputElement : null;
      }}
      showMask
    />
  );
});

CustomMaskedInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  other: PropTypes.shape({
    mask: PropTypes.array,
    placeholderChar: PropTypes.string,
  }),
};

CustomMaskedInput.defaultProps = {
  inputRef: null,
  other: {
    mask: [],
    placeholderChar: '',
  },
};

export default CustomMaskedInput;
