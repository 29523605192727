import { createTheme } from '@mui/material/styles';
import red from '@mui/material/colors/red';

export default createTheme({
  palette: {
    primary: { main: '#128C87' },
    secondary: { main: '#085C73' },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'black',
        },
        containedPrimary: {
          color: 'white',
          '&:hover': {
            backgroundColor: '#3F3E3E'
          }
        }
      },
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          '& > .RaSimpleFormIterator-list > .RaSimpleFormIterator-line': {
            border: '1px solid rgb(0, 0, 0, 0.23)',
            borderRadius: '4px',
            padding: '1rem',
            marginBottom: '1rem',
            paddingBottom: 0,
          },
        },
      },
    },
    RaArrayInput: {
      styleOverrides: {
        root: { maxWidth: '40%', width: '100%' },
      }
    },
    RaFileInput: {
      styleOverrides: {
        root: {
          '& .RaFileInput-dropZone': {
            border: '1px solid rgb(0, 0, 0, 0.23)',
            borderRadius: '4px',
            maxWidth: '40%',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: { maxWidth: '25rem', width: '100%' },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { maxWidth: '25rem', width: '100%' },
      },
    },
    RaRichTextInput: {
      styleOverrides: {
        root: { minWidth: '40%' },
      },
      editorContent: {
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          width: '100%',
        }
      }
    },
  },
});
