import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  useRecordContext,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { validateRequiredText } from 'utils/validation';
import SearchFilter from 'components/SearchFilter';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';

const CategoryList = props => (
  <List title='Categories' {...props} filters={<SearchFilter />} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' />
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredCategoryList = lockDown(CategoryList, [isUCPAdmin]);

const CategoryTitle = () => {
  const record = useRecordContext();
  return <span>Category {record ? `${record.name}` : ''}</span>;
};

const CategoryEdit = props => (
  <Edit title={<CategoryTitle />} mutationMode='pessimistic' {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Edit>
);

export const SecuredCategoryEdit = lockDown(CategoryEdit, [isUCPAdmin]);

const CategoryCreate = props => (
  <Create title='Create an Category' {...props}>
    <SimpleForm>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Create>
);

export const SecuredCategoryCreate = lockDown(CategoryCreate, [isUCPAdmin]);
