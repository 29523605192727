import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  FileField,
  TextField,
  DateTimeInput,
  required, Labeled,
} from 'react-admin';
import { RichTextInput, FormatButtons, RichTextInputToolbar } from 'ra-input-rich-text';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { validateRequired, validateRequiredText } from 'utils/validation';
import CustomMaskedInput from '../../CustomMaskedInput';
import { ALLOWED_EVENT_FILE_TYPES, EVENT_TYPE_OPTIONS, EVENT_TYPE_REGIONAL, EVENT_TYPE_STATEWIDE, EventTitle } from './index';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  richTextContainer: {
    '& .ql-container, & .ql-editor': {
      minHeight: '200px',
    },
  },
  editContainer: {
    '& .ra-input-startDate .MuiFormControl-root': {
      zIndex: 2,
    },
  },
});

const EventEdit = props => {
  const classes = useStyles();

  return (
    <Edit title={<EventTitle />} mutationMode='pessimistic' className={classes.editContainer} {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput source='title' validate={validateRequiredText} />
        <RichTextInput
          source='description'
          toolbar={
            <RichTextInputToolbar>
              <FormatButtons />
            </RichTextInputToolbar>
          }
          formClassName={classes.richTextContainer}
        />
        {/*Is Required*/}
        <DateTimeInput label='Event Date' source='startDate' validate={required()} />

        <BooleanInput source='allDayEvent' label='Is All Day Event' />
        <TextInput
          label='Duration'
          name='duration'
          source='duration'
          InputProps={{
            inputComponent: CustomMaskedInput,
            inputProps: {
              mask: [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/],
              placeholderChar: '\u2000',
            },
          }}
        />
        <SelectInput source='type' label='Type' validate={validateRequired} choices={EVENT_TYPE_OPTIONS} />
        <FormDataConsumer>
          {({ formData }) =>
            (formData.type === EVENT_TYPE_REGIONAL || formData.type === EVENT_TYPE_STATEWIDE) && (
              <>
                <ReferenceInput label='State' source='state' reference='AdminState'>
                  <AutocompleteInput optionText='name' />
                </ReferenceInput>
                {formData.type === EVENT_TYPE_REGIONAL && (
                  <ReferenceInput
                    label='Region'
                    source='region'
                    reference='AdminRegion'
                    filter={{ state: formData.state }}
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                )}
              </>
            )
          }
        </FormDataConsumer>
        <TextInput source='address' />
        <TextInput source='location' />
        <TextInput source='registrationUrl' />
        <ArrayInput source='attachments'>
          <SimpleFormIterator>
            <TextInput source='linkText' label='Link Text' validate={validateRequiredText} />

            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                getSource();
                return (
                  <Labeled label='File Name'>
                    <TextField source='document.fileName' record={scopedFormData} />
                  </Labeled>
                );
              }}
            </FormDataConsumer>
            <FileInput
              source='fileUpload'
              label='Attachments'
              accept={ALLOWED_EVENT_FILE_TYPES.join(',')}
              multiple={false}
              labelSingle='Drop a file here, or click to select a file to attach.'
            >
              <FileField source='src' title='title' />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default lockDown(EventEdit, [isUCPAdmin]);
