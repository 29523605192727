import React from 'react';
import {
  BooleanInput,
  Create,
  FormTab,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  AutocompleteInput,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import PercentageInput from 'components/PercentageInput';
import QuizQuestions from './components/QuizQuestions';
import { validateQuiz } from './index';

const QuizCreate = props => (
  <Create title='Create a Quiz' {...props}>
    <TabbedForm validate={validateQuiz}>
      <FormTab label='Quiz Options'>
        <Helmet>
          <title>{props?.options?.label}</title>
        </Helmet>
        <ReferenceInput
          label='Module'
          name='module'
          source='module'
          reference='AdminModule'
          sort={{ field: 'internalName', order: 'ASC' }}
        >
          <AutocompleteInput optionText={module => `${module.internalName} (${module.publishingStatus})`} />
        </ReferenceInput>
        <NumberInput
          name='numQuestionsToPresent'
          source='numQuestionsToPresent'
          label='Number of Questions Presented to a User'
        />
        <NumberInput
          name='completionTimeInSeconds'
          source='completionTimeInSeconds'
          label='Estimated Minutes to completion'
          format={v => v && v / 60}
          parse={v => v && v * 60}
        />
        <PercentageInput source='passingThresholdPercent' label='Passing Threshold' />
        <BooleanInput name='enabled' source='enabled' />
      </FormTab>

      <FormTab label='Questions'>
        <QuizQuestions />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default QuizCreate;
