import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  EditButton,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  required,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  minLength,
  useRecordContext,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';

const validateRequiredText = [required(), minLength(2)];

export const RegionIcon = LocationSearchingIcon;

const RegionList = props => (
  <List title='Regions' {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' label={'Region Name'} />
      <ReferenceField source='state' label={'Region State'} reference='AdminState' sortBy='state.name'>
        <TextField source='name' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredRegionList = lockDown(RegionList, [isUCPAdmin]);

const RegionTitle = () => {
  const record = useRecordContext();
  return <span>Region {record ? `${record.name}` : ''}</span>;
};

const RegionEdit = props => (
  <Edit title={<RegionTitle />} mutationMode='pessimistic' {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput
        source='name'
        label={'Region Name'}
        helperText={'Example: Chicagoland'}
        validate={validateRequiredText}
      />
      <ReferenceInput
        label={'Region State'}
        source='state'
        reference='AdminState'
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText='name' helperText={'Example: Illinois'} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const SecuredRegionEdit = lockDown(RegionEdit, [isUCPAdmin]);

const RegionCreate = props => (
  <Create title='Create a Region' {...props}>
    <SimpleForm>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput
        source='name'
        label={'Region Name'}
        helperText={'Example: Chicagoland'}
        validate={validateRequiredText}
      />
      <ReferenceInput
        label={'Region State'}
        source='state'
        reference='AdminState'
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText='name' helperText={'Example: Illinois'} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const SecuredRegionCreate = lockDown(RegionCreate, [isUCPAdmin]);
