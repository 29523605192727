import React from 'react';
import { TextInput } from 'react-admin';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

const SearchFilter = (props) => (
  <SavelessFilter {...props}>
    <TextInput name='q' label='Search' source='q' alwaysOn />
  </SavelessFilter>
);

export default SearchFilter;
