import jwtDecode from 'jwt-decode';
import { client } from 'App';
import { isAllowedAdmin } from 'utils/roles';
import { setToken, getUserData } from 'utils/auth';
import { clearSessionStorage, isAuthenticated } from 'utils/auth';
import { gql } from '@apollo/client';

const LOGIN_MUTATION = gql(`
  mutation login($email: Email!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      jwt
    }
  }
`);

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const res = await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          email: username,
          password,
        },
      });

      const {
        data: { login: jwt },
      } = res;
      const data = jwtDecode(jwt.jwt);

      if (!data || !data.roles || !isAllowedAdmin(data)) {
        return Promise.reject('You do not have permission to access this resource.');
      }

      setToken(jwt.jwt);
      /**
       * Setting the token alone doesn't do the trick, we mush force a page reload for new permission to take effect.
       */
      return Promise.resolve(window.location.reload());
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
  logout: () => {
    clearSessionStorage();
    return Promise.resolve();
  },
  checkError: error => {
    const status = error?.status;

    if ((status === undefined && !isAuthenticated()) || status === 401 || status === 403) {
      clearSessionStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return isAuthenticated() ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = getUserData() && getUserData().roles[0];
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
