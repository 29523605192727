import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { EventFilter } from './index';
import { makeStyles } from '@mui/styles';
import UtcDateField from 'components/UtcDateField';

const useStyles = makeStyles({
  eventList: {
    '& > .MuiToolbar-root': {
      zIndex: 10,
    },
  },
});

const EventList = props => {
  const classes = useStyles();

  return (
    <List
      className={classes.eventList}
      title='Events'
      {...props}
      filters={<EventFilter />}
      sort={{ field: 'startDate', order: 'ASC' }}
    >
      <Datagrid>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextField source='title' />

        {/*TODO : Figure out how to display a date time input here */}
        <UtcDateField label='Start Date' source='startDate' />

        <TextField source='type' sortable={false} />
        <TextField source='duration' sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default lockDown(EventList, [isUCPAdmin]);
