import { gql } from '@apollo/client';
export const ALL_ADMIN_MODULES = gql`
  query allAdminModules($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: AdminModuleFilter) {
    items: allAdminModules(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      internalName
      title
      description
      catalogNumber
      categories
      presenters
      accessLevel
      learningCredits
      occupations
      featuredStart
      featuredEnd
      publishingStatus
      mp4Low
      mp4Medium
      mp4High
      mp4ExtraHigh
      webMLow
      webMMedium
      webMHigh
      webMExtraHigh
      videoCaptionLink
      videoThumbnailLink
      legacyScoId
      isMasaContent
      isBadgeContent
      isBreezePresentation
      breezePresentationLink
      introAudio
      resultsAudio
      markets
      externalLinks {
        linkText
        url
        __typename
      }
      privateContentAgencies
      playingTimeInSeconds
      hasIsbeCompletionCertificate
      metaData {
        totalViews
        __typename
      }
      isNewsletterAccessible
      newsletterExpiration
      __typename
    }
    total: _allAdminModulesMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
      __typename
    }
  }
`;
