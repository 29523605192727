import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  useRecordContext,
} from 'react-admin';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isTemplateAdmin, isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { ModuleFilter } from './index';
import * as clipboard from 'clipboard-polyfill';

const ModuleLinkField = ({ source }) => {
  const record = useRecordContext() ?? {};

  return record && record.isNewsletterAccessible ? (
    <>
      <a href={`${process.env.REACT_APP_FRONT_END_URL}/module/${get(record, 'id', '')}?referrer=newsletter`}>
        Newsletter Link
      </a>
      <button
        onClick={() =>
          clipboard.writeText(
            `${process.env.REACT_APP_FRONT_END_URL}/module/${get(record, 'id', '')}?referrer=newsletter`
          )
        }
      >
        Copy
      </button>
    </>
  ) : null;
};

const ModuleList = props => (
  <List title='Modules' {...props} filters={<ModuleFilter />} sort={{ field: 'internalName', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='internalName' label='Name' />
      <TextField source='catalogNumber' />
      <TextField source='title' />
      <TextField source='accessLevel' />
      <TextField source='publishingStatus' />
      <TextField source='metaData.totalViews' label='View Count' />
      <ReferenceArrayField label='Presenters' reference='AdminPresenter' source='presenters' sortable={false}>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      <ModuleLinkField />
      <EditButton />
    </Datagrid>
  </List>
);

export default lockDown(ModuleList, [isUCPAdmin, isTemplateAdmin]);
