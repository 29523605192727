import React from 'react';
import { FormDataConsumer, ImageField, ImageInput, Labeled, TextField, useInput, useRecordContext } from 'react-admin';
import { MAX_FILE_SIZE } from 'utils/consts';
import { validateRequired } from 'utils/validation';
import { ALLOWED_BADGE_FILE_TYPES } from '../index';

const BadgeImageFormGroup = () => {
  const record = useRecordContext();
  const input = useInput({
    source: 'badgeImage',
    defaultValue: record.badge.image,
  });

  return (
    <>
      <FormDataConsumer>
        {props => (
          <Labeled label='Image File Name'>
            <TextField source='badge.image.fileName' {...props} />
          </Labeled>
        )}
      </FormDataConsumer>
      <ImageInput
        name='badgeImage'
        source='badgeImage'
        label='Image'
        accept={ALLOWED_BADGE_FILE_TYPES.join(',')}
        multiple={false}
        maxSize={MAX_FILE_SIZE}
        validate={validateRequired}
        labelSingle='Drop an image here, or click to select an image to attach.'
        helperText='Images that are 200 x 280 will look best for badges.'
      >
        <ImageField source='src' title='title' />
      </ImageInput>
    </>
  )
}

export default BadgeImageFormGroup;
