import { gql } from '@apollo/client';
export const ALL_ADMIN_CUSTOM_PAGES = gql`
  query allAdminCustomPages($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: AdminCustomPageFilter) {
    items: allAdminCustomPages(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      parent
      slug
      url
      title
      published
      isInfinitextAccessPage
      __typename
    }
    total: _allAdminCustomPagesMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
      __typename
    }
  }
`;
