import { gql } from '@apollo/client';
export const ALL_ADMIN_EVENTS = gql`
  query allAdminEvents($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: AdminEventFilter) {
    items: allAdminEvents(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      title
      description
      startDate
      allDayEvent
      duration
      type
      state
      region
      address
      location
      registrationUrl
      __typename
    }
    total: _allAdminEventsMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
      __typename
    }
  }
`;
