import React from 'react';
import { List, Datagrid, TextField, BooleanField, EditButton } from 'react-admin';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { CustomPageFilter } from './index';

const CustomPageList = props => (
  <List title='Pages' {...props} filters={<CustomPageFilter />} sort={{ field: 'title', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='title' />
      <TextField source='url' sortable={false} />
      <BooleanField source='published' sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default lockDown(CustomPageList, [isUCPAdmin]);
