import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  BooleanInput,
} from 'react-admin';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin, isTemplateAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { InfLearningTemplateTitle, PUBLISHING_STATUS_OPTIONS } from './index';
import { validateRequired, validateRequiredText } from 'utils/validation';
import SortableModulesIterator from './components/SortableModulesIterator';
import useRichTextStyles from 'hooks/useRichTextStyles';
import { RichTextInput } from 'ra-input-rich-text';
import RichTextToolbar from 'components/RichTextToolbar';
import { MODULE_STATUS_PUBLISHED } from 'utils/consts';

const InfLearningTemplateEdit = props => {
  const classes = useRichTextStyles();

  const moduleFilter = {
    publishingStatus: [MODULE_STATUS_PUBLISHED]
  };

  return (
    <Edit title={<InfLearningTemplateTitle />} mutationMode='pessimistic' {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput source='name' validate={validateRequiredText} />
        <SelectInput
          name='status'
          source='status'
          label='Publishing Status'
          validate={validateRequired}
          choices={PUBLISHING_STATUS_OPTIONS}
        />
        <br />

        <RichTextInput
          name='description'
          source='description'
          label='Campaign Description'
          toolbar={RichTextToolbar}
          formClassName={classes.richTextContainer}
        />
        <RichTextInput
          source='instructions'
          label='Campaign Instructions'
          toolbar={RichTextToolbar}
          formClassName={classes.richTextContainer}
        />
        <br />

        <ReferenceArrayInput
          source='markets'
          label='Work Sectors'
          reference='AdminMarket'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source='templateCategories'
          label='Category'
          reference='AdminTemplateCategory'
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>

        {/* Activity Sequencing */}
        <BooleanInput label='Enforce Activity Sequence' name='enforceActivitySequence' source='enforceActivitySequence' />
        <BooleanInput label='Allow Sequence Override' name='allowSequenceOverride' source='allowSequenceOverride' />
        <br />

        <ArrayInput source='modules'>
          <SortableModulesIterator moduleFilter={moduleFilter} />
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default lockDown(InfLearningTemplateEdit, [isUCPAdmin, isTemplateAdmin]);
