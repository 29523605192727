import React from 'react';
import momentTimezone from 'moment-timezone';
import { useRecordContext } from 'react-admin';

function CentralDateField({ source, format = 'M/DD/YYYY hh:mm:ss A z' }) {
  const record = useRecordContext() ?? {};
  return (
    <span>
      {momentTimezone(record[source])
        .tz('America/Chicago')
        .format(format)}
    </span>
  );
}

export default CentralDateField;
