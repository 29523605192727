import React from 'react';
import { withStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import ActionDelete from '@mui/icons-material/Delete';
import classnames from 'classnames';
import { DeleteWithConfirmButton } from 'react-admin';

const customDeleteStyles = theme => ({
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    // Reset on mouse devices
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
});

const CustomDeleteButton = withStyles(customDeleteStyles)(({ item }) => {
  return (
    <DeleteWithConfirmButton
      className={classnames('ra-delete-button', 'deleteButton', 'delete-button')}
      confirmContent='Are you sure you want to delete this item?'
      confirmTitle={`Delete item: ${item}`}
      label='ra.action.delete'
      icon={<ActionDelete />}
      sx={{ color: (theme) => theme.palette.error.main, padding: '6px 16px' }}
    />
  );
});

export default CustomDeleteButton;
