import React from 'react';
import { ArrayInput, BooleanInput, SelectInput, SimpleFormIterator, TextInput  } from 'react-admin';
import { RichTextInput, FormatButtons, RichTextInputToolbar } from 'ra-input-rich-text';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  richTextContainer: {
    '& .ql-container, & .ql-editor': {
      minHeight: '200px',
    },
  },
  answerOptionsContainer: {
    '& .Mui-error': {
      marginTop: '1rem',
    },
  },
});

const QuizQuestions = () => {
  const classes = useStyles();

  return (
    <ArrayInput name='questions' source='questions' sx={{ maxWidth: '100%', width: { xs: '100%', md: 'auto' } }}>
      <SimpleFormIterator fullWidth={true}>
        <SelectInput
          source='quizQuestionType'
          label='Question Type'
          choices={[
            { id: 'single', name: 'Single Select' },
            { id: 'multiple', name: 'Multiple Select' },
          ]}
        />
        <RichTextInput
          source='questionText'
          label='Question Text'
          toolbar={
            <RichTextInputToolbar>
              <FormatButtons />
            </RichTextInputToolbar>
          }
          formClassName={classes.richTextContainer}
        />
        <ArrayInput
          source='answerOptions'
          label='Answers'
          formClassName={classes.answerOptionsContainer}
          sx={{ maxWidth: '100%' }}
        >
          <SimpleFormIterator fullWidth={true}>
            <TextInput source='answerText' label='Answer Text' multiline />
            <BooleanInput source='isCorrect' label='Is Correct' defaultValue={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default QuizQuestions;
