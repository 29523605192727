import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  NumberField,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import { isUCPAdmin, isTemplateAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import get from 'lodash/get';
import { InfLearningTemplateFilter } from './index';

const InfLearningTemplateList = props => (
  <List
    title='INF Learning Templates'
    {...props}
    filters={<InfLearningTemplateFilter />}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' />
      <TextField source='status' />
      <ReferenceArrayField label='Work Sector' reference='AdminMarket' source='markets' sortable={false}>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
      <NumberField label='Copy Count' source='stats.copyCount' />
      <NumberField label='Apply Count' source='stats.applyCount' />
      <NumberField label='Total Uses' source='stats.totalUses' />
      <EditButton />
    </Datagrid>
  </List>
);

export default lockDown(InfLearningTemplateList, [isUCPAdmin, isTemplateAdmin]);
