import jwtDecode from 'jwt-decode';
import moment from 'moment';

const USER_AUTH_TOKEN = 'infinite_admin__auth_token';

export const getToken = () => localStorage.getItem(USER_AUTH_TOKEN);

export const setToken = token => localStorage.setItem(USER_AUTH_TOKEN, token);

export const removeToken = () => localStorage.removeItem(USER_AUTH_TOKEN);

export const getUserData = () => isAuthenticated() && jwtDecode(getToken());

export const isTokenExpired = () => {
  const expiration = jwtDecode(getToken())?.exp;

  if (expiration) {
    const expirationMoment = moment(expiration * 1000);
    const tokenExpiration = expirationMoment.diff(moment().utc());

    return tokenExpiration <= 0;
  }

  return false;
};

export const isAuthenticated = () => {
  return !!getToken() && !isTokenExpired();
};

export const clearSessionStorage = () => {
  removeToken();
};

export const lockDown = (Component, tests) => {
  if (tests.some(test => test(getUserData()))) {
    return Component;
  }

  return '';
};
