import React from 'react';
import { Layout } from 'react-admin';
import AdminAppBar from 'components/theme/AdminAppBar';
import { ReactQueryDevtools } from 'react-query/devtools';

function AdminLayout(props) {
  return (
    <>
      <Layout {...props} appBar={AdminAppBar} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default AdminLayout;
