import React from 'react';
import QuizList from './QuizList';
import QuizCreate from './QuizCreate';
import QuizEdit from './QuizEdit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { isUCPAdmin, isQuizAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { AutocompleteInput, ReferenceInput, TextInput, BooleanInput, useRecordContext } from 'react-admin';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

export function validateQuiz(values) {
  const errors = {};

  if (!values.module) {
    errors.module = 'Please select a module for this quiz.';
  }

  if (!values.numQuestionsToPresent) {
    errors.numQuestionsToPresent = 'Please enter the number of questions to present.';
  } else if (values.numQuestionsToPresent > (values.questions?.length || 0)) {
    errors.numQuestionsToPresent = 'Quizzes can not display more questions than exist.';
  }

  if (!values.completionTimeInSeconds) {
    errors.completionTimeInSeconds = 'The estimated time to complete is required.';
  }

  if (!values.passingThresholdPercent) {
    errors.passingThresholdPercent = 'A minimum score to pass the quiz is required.';
  }

  if (Array.isArray(values.questions) && values.questions.length > 0) {
    const questionsErrors = values.questions?.map(question => {
      const questionErrors = {};

      if (!question.quizQuestionType) {
        questionErrors.quizQuestionType = 'Question type is required.';
      }

      if (!question.questionText) {
        questionErrors.questionText = 'Question text is required';
      }

      if (question.answerOptions?.length > 1) {
        let hasErrors = false;
        const answerErrors = question?.answerOptions.map((option) => {
          const answerErrors = {};

          if (!option.answerText) {
            answerErrors.answerText = 'Answer text is required.';
            hasErrors = true;
          }

          return answerErrors;
        });

        if (hasErrors) {
          // nested array inputs can not return a value to pass validation.
          questionErrors.answerOptions = answerErrors;
        }
      } else {
        questionErrors.answerOptions = 'Questions should have at least two response options.';
      }

      return questionErrors;
    });

    if (Object.keys(questionsErrors).length) {
      errors.questions = questionsErrors;
    }
  }

  return errors;
}

export const QuizIcon = QuestionAnswerIcon;

export const QuizTitle = () => {
  const record = useRecordContext();
  return <span>Quiz {record ? record.module : ''}</span>;
};

export const QuizFilter = props => (
  <SavelessFilter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <ReferenceInput label='Module Name' source='module' reference='AdminModule' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText='internalName' />
    </ReferenceInput>
    <BooleanInput source='enabled' defaultValue={true} />
  </SavelessFilter>
);

const SecuredQuizList = lockDown(QuizList, [isUCPAdmin, isQuizAdmin]);
const SecuredQuizCreate = lockDown(QuizCreate, [isUCPAdmin, isQuizAdmin]);
const SecuredQuizEdit = lockDown(QuizEdit, [isUCPAdmin, isQuizAdmin]);

export { SecuredQuizList, SecuredQuizCreate, SecuredQuizEdit };
