import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  moduleCard: {
    display: 'flex',
    zIndex: 999,
    border: '1px solid rgb(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: '1rem 0.5rem 0',
    marginBottom: '1rem',
  },
});

const ModuleCard = props => {
  const classes = useStyles();

  return <div {...props} className={classes.moduleCard} />;
};

export default ModuleCard;
