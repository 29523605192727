import React from 'react';
import { lockDown } from '../../utils/auth';
import { isUCPAdmin } from '../../utils/roles';
import { Button, Title } from 'react-admin';
import { JOB_FUNCTION_NAMES } from '../../utils/consts';
import CustomUploadButton from './components/CustomUploadButton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  emailUpdateUploadForm: {
    backgroundColor: 'white',
    padding: '1rem',
  },
  uploadButtonWrapper: {
    marginTop: '1rem',
  },
});

const BulkUserUpdate = () => {
  const classes = useStyles();

  return (
    <div className={classes.emailUpdateUploadForm}>
      <Title title='Bulk User Update' />

      <p>
        Please select a spreadsheet containing the emails you would like to update. This file will be uploaded and
        processed. Upon completion a notification will be emailed to you stating the results of the process.
      </p>

      <p>Note: The first row of the spreadsheet will be ignored to account for headers.</p>

      <div className={classes.uploadButtonWrapper}>
        <CustomUploadButton
          name='batchEmailUpdateFileSubmit'
          jobName={JOB_FUNCTION_NAMES.BATCH_USER_UPDATE}
          accept='.xls,.csv,.xlsx'
        />
      </div>

      <p>
        For your convenience Infinitec provides a template:
        <Button label='Download Template' onClick={() => (window.location = 'batchEmailUpdateTemplate.xls')} />
      </p>
    </div>
  );
};

export default lockDown(BulkUserUpdate, [isUCPAdmin]);
