import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ModuleCard from './components/ModuleCard';
import ModuleList from './components/ModuleList';
import AddButton from './components/AddButton';

const SortableModuleCard = SortableElement(props => <ModuleCard {...props} />);

const SortableModuleList = SortableContainer(({ fields, removeField, moduleFilter }) => {
  return (
    <div>
      {fields.map((field, index) => (
        <SortableModuleCard key={field.id} index={index}>
          <ModuleList index={index} removeField={removeField} moduleFilter={moduleFilter}/>
        </SortableModuleCard>
      ))}
    </div>
  );
});

const SortableModulesIterator = ({moduleFilter}) => {
  const { fields, append, remove, move } = useFieldArray({
    name: 'modules',
  });

  return (
    <div>
      <SortableModuleList
        useDragHandle={true}
        fields={fields}
        removeField={remove}
        onSortEnd={({ oldIndex, newIndex }) => {
          move(oldIndex, newIndex);
        }}
        moduleFilter={moduleFilter}
      />

      <AddButton
        handleClick={e => {
          e.preventDefault();
          append({});
        }}
      />
    </div>
  );
};

export default SortableModulesIterator;
