import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import Logo from './Logo';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: '1.5rem',
  },
  spacer: {
    flex: 1,
  },
});

const AdminAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Logo />
      <Typography variant='h6' color='inherit' className={classes.title} id='react-admin-title' />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default AdminAppBar;
