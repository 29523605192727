import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput, minValue, maxValue } from 'react-admin';

const BASE_VALIDATION = [
  minValue(0, 'You must select a percentage greater than 0'),
  maxValue(1, 'You must select a percentage less than 100'),
];

const PercentageInput = ({ source, label, validate }) => {
  return (
    <NumberInput
      source={source}
      label={label}
      validate={BASE_VALIDATION.concat(validate)}
      format={v => v && Math.round(v * 100)}
      parse={v => v && v / 100}
      step={1}
    />
  );
};

PercentageInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func),
};

PercentageInput.defaultProps = {
  label: undefined,
  validate: [],
};

export default PercentageInput;
