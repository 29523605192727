import React from 'react';
import { Helmet } from 'react-helmet';
import {
  BooleanField,
  Datagrid,
  DateField,
  Edit,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TabbedForm,
  TextField,
} from 'react-admin';
import CustomToolbar from 'components/CustomToolbar';
import { isUCPAdmin, isUserAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { UserTitle } from './index';
import get from 'lodash/get';
import BaseUserFieldGroup from './components/BaseUserFieldGroup';

const UserEdit = ({ permissions, ...props }) => (
  <Edit title={<UserTitle />} mutationMode='pessimistic' {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label='User Info'>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <BaseUserFieldGroup />
      </FormTab>
      <FormTab label='Learning Campaigns'>
        <FormDataConsumer>
          {props => (
            <>
              <ReferenceInput
                label='Add user to campaign'
                name='learningCampaign'
                source='learningCampaign'
                reference='AdminLearningCampaign'
                filter={{ agency: props.formData.memberAgency }}
              >
                <SelectInput optionText='name' helperText='User will be added to the selected campaign' />
              </ReferenceInput>
              <ReferenceManyField
                {...props}
                label='Learning Campaigns'
                reference='AdminLearningCampaign'
                target='adminUploadedUsers'
              >
                <Datagrid>
                  <TextField source='name' />
                  <DateField source='startDate' />
                  <DateField source='endDate' />
                  <BooleanField source='isActive' sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </>
          )}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default lockDown(UserEdit, [isUCPAdmin, isUserAdmin]);
