import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  required,
  minLength,
  useRecordContext,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import WorkIcon from '@mui/icons-material/Work';
import SearchFilter from 'components/SearchFilter';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';

const validateRequiredText = [required(), minLength(2)];

export const OccupationIcon = WorkIcon;

const OccupationList = props => (
  <List title='Occupations' {...props} filters={<SearchFilter />} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' />
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredOccupationList = lockDown(OccupationList, [isUCPAdmin]);

const OccupationTitle = () => {
  const record = useRecordContext();
  return <span>Occupation {record ? `${record.name}` : ''}</span>;
};

const OccupationEdit = props => (
  <Edit title={<OccupationTitle />} mutationMode='pessimistic' {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Edit>
);

export const SecuredOccupationEdit = lockDown(OccupationEdit, [isUCPAdmin]);

const OccupationCreate = props => (
  <Create title='Create an Occupation' {...props}>
    <SimpleForm>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Create>
);

export const SecuredOccupationCreate = lockDown(OccupationCreate, [isUCPAdmin]);
