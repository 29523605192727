import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  FileInput,
  FileField,
  DateTimeInput,
  required,
} from 'react-admin';
import { RichTextInput, FormatButtons, RichTextInputToolbar } from 'ra-input-rich-text';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';
import { validateRequired, validateRequiredText } from 'utils/validation';
import CustomMaskedInput from '../../CustomMaskedInput';
import { ALLOWED_EVENT_FILE_TYPES, EVENT_TYPE_OPTIONS, EVENT_TYPE_REGIONAL, EVENT_TYPE_STATEWIDE } from './index';
import { makeStyles } from '@mui/styles';
import { MAX_FILE_SIZE } from 'utils/consts';

const useStyles = makeStyles({
  richTextContainer: {
    '& .ql-container, & .ql-editor': {
      minHeight: '200px',
    },
  },
  formContainer: {
    '& .ra-input-startDate .MuiFormControl-root': {
      zIndex: 2,
    },
  },
});

const EventCreate = props => {
  const classes = useStyles();

  return (
    <Create title='Create an Event' className={classes.formContainer} {...props}>
      <SimpleForm>
        <Helmet>
          <title>{get(props, 'options.label', '')}</title>
        </Helmet>
        <TextInput source='title' validate={validateRequiredText} />
        <RichTextInput
          source='description'
          toolbar={
            <RichTextInputToolbar>
              <FormatButtons />
            </RichTextInputToolbar>
          }
          formClassName={classes.richTextContainer}
        />

        {/*Is Required*/}
        <DateTimeInput label='Event Date' source='startDate' validate={required()} />

        <BooleanInput source='allDayEvent' label='Is All Day Event' />
        <TextInput
          label='Duration'
          name='duration'
          source='duration'
          InputProps={{
            inputComponent: CustomMaskedInput,
            inputProps: {
              mask: [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/],
              placeholderChar: '\u2000',
            },
          }}
        />
        <SelectInput source='type' label='Type' validate={validateRequired} choices={EVENT_TYPE_OPTIONS} />
        <FormDataConsumer>
          {({ formData }) =>
            (formData.type === EVENT_TYPE_REGIONAL || formData.type === EVENT_TYPE_STATEWIDE) && (
              <>
                <ReferenceInput label='State' source='state' reference='AdminState'>
                  <AutocompleteInput optionText='name' />
                </ReferenceInput>
                {formData.type === EVENT_TYPE_REGIONAL && (
                  <ReferenceInput
                    label='Region'
                    source='region'
                    reference='AdminRegion'
                    filter={{ state: formData.state }}
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                )}
              </>
            )
          }
        </FormDataConsumer>
        <TextInput source='address' />
        <TextInput source='location' />
        <TextInput source='registrationUrl' />
        <ArrayInput source='attachments'>
          <SimpleFormIterator>
            <TextInput source='linkText' label='Link Text' validate={validateRequiredText} />
            <FileInput
              source='fileUpload'
              label='Attachments'
              accept={ALLOWED_EVENT_FILE_TYPES.join(',')}
              multiple={false}
              maxSize={MAX_FILE_SIZE}
              labelSingle='Drop a file here, or click to select a file to attach.'
            >
              <FileField source='src' title='title' />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default lockDown(EventCreate, [isUCPAdmin]);
