import React from 'react';
import { LinearProgress, useRecordContext, useReference } from 'react-admin';

function LearningCreditField() {
  const record = useRecordContext();
  const { isLoading, referenceRecord } = useReference({ reference: 'AdminModule', id: record.moduleId });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      {record.status === 'Pass' && referenceRecord && referenceRecord.learningCredits
        ? referenceRecord.learningCredits
        : ''}
    </>
  );
}

export default LearningCreditField;
