import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  required,
  minLength,
  useRecordContext,
} from 'react-admin';
import CustomToolbar from '../CustomToolbar';
import MuseumOutlinedIcon from '@mui/icons-material/MuseumOutlined';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { isUCPAdmin } from 'utils/roles';
import { lockDown } from 'utils/auth';

const validateRequiredText = [required(), minLength(2)];

export const MarketIcon = MuseumOutlinedIcon;

const MarketList = props => (
  <List title='Work Sector' {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextField source='name' />
      <EditButton />
    </Datagrid>
  </List>
);

export const SecuredMarketList = lockDown(MarketList, [isUCPAdmin]);

const MarketTitle = () => {
  const record = useRecordContext();
  return <span>Work Sector: {record ? `${record.name}` : ''}</span>;
};

const MarketEdit = props => (
  <Edit title={<MarketTitle />} mutationMode='pessimistic' {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Edit>
);

export const SecuredMarketEdit = lockDown(MarketEdit, [isUCPAdmin]);

const MarketCreate = props => (
  <Create title='Create an Work Sector' {...props}>
    <SimpleForm>
      <Helmet>
        <title>{get(props, 'options.label', '')}</title>
      </Helmet>
      <TextInput source='name' validate={validateRequiredText} />
    </SimpleForm>
  </Create>
);

export const SecuredMarketCreate = lockDown(MarketCreate, [isUCPAdmin]);
