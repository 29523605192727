import React from 'react';
import {
  BooleanInput,
  Button,
  Edit,
  FormTab,
  NumberInput,
  ReferenceInput,
  TabbedForm,
  FormDataConsumer,
  AutocompleteInput,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import { QuizTitle, validateQuiz } from './index';
import QuizQuestions from './components/QuizQuestions';
import PercentageInput from 'components/PercentageInput';

const QuizEdit = props => (
  <>
    <Helmet>
      <title>{props?.options?.label}</title>
    </Helmet>
    <Edit title={<QuizTitle />} mutationMode='pessimistic' {...props}>
      <TabbedForm validate={validateQuiz}>
        <FormTab label='Quiz Options'>
          <ReferenceInput
            name='module'
            label='Module'
            source='module'
            reference='AdminModule'
            sort={{ field: 'internalName', order: 'ASC' }}
          >
            <AutocompleteInput optionText={module => `${module.internalName} (${module.publishingStatus})`} />
          </ReferenceInput>
          <NumberInput
            name='numQuestionsToPresent'
            source='numQuestionsToPresent'
            label='Number of Questions Presented to a User'
          />
          <NumberInput
            name='completionTimeInSeconds'
            source='completionTimeInSeconds'
            label='Estimated Minutes to completion'
            format={v => v && v / 60}
            parse={v => v && v * 60}
          />
          <PercentageInput source='passingThresholdPercent' label='Passing Threshold' />
          <BooleanInput name='enabled' source='enabled' />

          <FormDataConsumer>
            {({ formData }) => (
              <div>
                <Button
                  label='Preview Quiz'
                  href={`${process.env.REACT_APP_FRONT_END_URL}/quiz/${formData.id}/intro`}
                  target='_blank'
                />
              </div>
            )}
          </FormDataConsumer>
        </FormTab>

        <FormTab label='Questions'>
          <QuizQuestions />
        </FormTab>
      </TabbedForm>
    </Edit>
  </>
);

export default QuizEdit;
