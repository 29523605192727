import React from 'react';
import logoImg from './assets/infinitec-logo.jpg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  logo: {
    height: '3.125rem',
    backgroundColor: 'white',
    paddingRight: '10px',
    '& img': {
      maxHeight: '100%',
    },
  },
});

const propTypes = {};

function Logo() {
  const classes = useStyles();
  return (
    <a href='/' className={classes.logo}>
      <img src={logoImg} alt='Infinitec' />
    </a>
  );
}

Logo.propTypes = propTypes;

export default Logo;
