import React, { useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Button, useNotify } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { getToken } from 'utils/auth';

const useStyles = makeStyles({
  fileInput: {
    display: 'none',
  },
});

function CustomUploadButton({ name, accept, jobName }) {
  const notify = useNotify();
  const classes = useStyles();
  const inputRef = useRef(null);

  const handleSubmit = async event => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('selectedFile', event.target.files[0]);
    formData.append('jobName', jobName);

    try {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/batch-email-update?bearerToken=${getToken()}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      inputRef.current.value = null;
      notify('Your file has been uploaded and is processing.  We will email you when processing is completed.');
    } catch (error) {
      notify('There was a problem uploading your file, please try again.');
    }
  };

  return (
    <div>
      <Button label='Upload File' onClick={() => inputRef.current.click()} />
      <input
        id={`file_upload_${name}`}
        name={name}
        accept={accept}
        type='file'
        onChange={handleSubmit}
        className={classes.fileInput}
        ref={inputRef}
      />
    </div>
  );
}

CustomUploadButton.propTypes = {
  jobName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
};

export default CustomUploadButton;
