import React from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button } from '@mui/material';

const RemoveButton = ({ handleClick }) => {

  return (
      <Button
        onClick={handleClick}
        aria-label='Remove button'
      >
          <RemoveCircleOutlineIcon sx={{marginRight: '0.4rem'}}/> Remove
      </Button>
  );
};

export default RemoveButton;
