import * as React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';
import { convertFileToBase64 } from 'utils/dataProvider';

export const CustomImageButton = (props) => {
  const translate = useTranslate();
  const editor = useTiptapEditor();

  const inputRef = React.useRef();

  const label = props.label || translate('ra.tiptap.image', { _: 'Image' });

  const addImage = React.useCallback(async (event) => {
    const file = event.target.files[0];

    if (file) {
      const base64Image = await convertFileToBase64(file);
      editor.chain().focus().setImage({ src: base64Image }).run();
    }
  }, [editor]);

  const triggerUpload = React.useCallback(() => {
    inputRef.current && inputRef.current.click && inputRef.current.click();
  }, [])

  return (
    <ToggleButton
      aria-label={label}
      data-test-id='custom-add-image'
      title={label}
      {...props}
      disabled={!editor || !editor.isEditable}
      value="image"
      onClick={triggerUpload}
    >
      <input type='file' name='addimage' ref={inputRef} accept='image/png,image/jpeg,image/svg' onChange={addImage} style={{ display: 'none' }} />
      <ImageIcon fontSize="inherit" />
    </ToggleButton>
  );
};

CustomImageButton.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
};

export default CustomImageButton;
