import React from 'react';
import ViewEventIcon from '@mui/icons-material/Event';
import SecuredEventCreate from './EventCreate';
import SecuredEventList from './EventList';
import SecuredEventEdit from './EventEdit';
import { DateInput, SelectInput, TextInput, useRecordContext } from 'react-admin';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

// Converted to Mime types '.doc,.docx,.pdf,.ppt,.csv,.xlsx'
export const ALLOWED_EVENT_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const EVENT_TYPE_WEBINAR = 'webinar';
export const EVENT_TYPE_STATEWIDE = 'statewide';
export const EVENT_TYPE_REGIONAL = 'regional';

export const EVENT_TYPE_OPTIONS = [
  { id: EVENT_TYPE_WEBINAR, name: 'Webinar' },
  { id: EVENT_TYPE_STATEWIDE, name: 'Statewide' },
  { id: EVENT_TYPE_REGIONAL, name: 'Regional' },
];

export const EventIcon = ViewEventIcon;

export const EventTitle = () => {
  const record = useRecordContext();
  return <span>Event {record ? `"${record.title}"` : ''}</span>;
};

export const EventFilter = props => (
  <SavelessFilter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
    <DateInput source='startDate' label='Start Date' />
    <SelectInput source='type' label='Type' choices={EVENT_TYPE_OPTIONS} />
  </SavelessFilter>
);

export { SecuredEventList, SecuredEventCreate, SecuredEventEdit };
