import React from 'react';
import Icon from '@mui/icons-material/Assistant';
import SecuredInfLearningTemplateCreate from './InfLearningTemplateCreate';
import SecuredInfLearningTemplateList from './InfLearningTemplateList';
import SecuredInfLearningTemplateEdit from './InfLearningTemplateEdit';
import { AutocompleteInput, ReferenceInput, TextInput, useRecordContext } from 'react-admin';
import { SavelessFilter } from 'components/SavelessFilter/SavelessFilter';

export const PUBLISHING_STATUS_OPTIONS = [
  { id: 'draft', name: 'Draft' },
  { id: 'published', name: 'Published' },
  { id: 'archived', name: 'Archived' },
];

export const InfLearningTemplateIcon = Icon;

export const InfLearningTemplateTitle = () => {
  const record = useRecordContext();
  return <span>INF Learning Template {record ? `- "${record.name}"` : ''}</span>;
};

export const InfLearningTemplateFilter = props => (
  <SavelessFilter {...props}>
    <TextInput name='q' label='Search' source='q' alwaysOn />
    <ReferenceInput name='markets' label='Work Sector' source='markets' reference='AdminMarket' sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput name='markets' optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      name='templateCategories'
      label='Category'
      source='templateCategories'
      reference='AdminTemplateCategory'
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput name='templateCategories' optionText='name' />
    </ReferenceInput>
  </SavelessFilter>
);

export { SecuredInfLearningTemplateCreate, SecuredInfLearningTemplateList, SecuredInfLearningTemplateEdit };
